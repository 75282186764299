import React from "react";
import styled from "styled-components";
import BookIcon from "../svg/book.svg";
import QuestionIcon from "../svg/question-circle.svg";
import CrownIcon from "../svg/crown.svg";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";

const HeaderWrapper = styled.div`
    z-index: 100;
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: #c9e8d4;
    align-items: center;
    justify-content: space-between;
    color: #313444;
    top: 0;
    margin: 0;
    padding-top: 0.5rem;

    @media (max-width: 720px) {
        flex-direction: column;
        // position: sticky; //only have sticky menu when the clicking links automatically closes the menu
    }
`;

const MenuButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @media (max-width: 720px) {
        width: 100%;
    }
`;

const LogoWrapper = styled.div`
    min-width: 162px;
`;

const BurgerButton = styled.div`
    background-color: ${(props) =>
        props.isOpen ? "rgba(255,255,255,0.52)" : "rgba(255,255,255,0.12)"};
    border: solid 1px rgba(255, 255, 255, 0.2);
    min-width: 40px;
    min-height: 40px;
    border-radius: 1rem;
    margin: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0.5rem;
    font-weight: 700;
    gap: 0.5rem;

    @media (min-width: 720px) {
        display: none;
    }
`;

const MenuLinksWrapper = styled.div`
    max-height: ${(props) => (props.isOpen ? "250px" : "0")};
    overflow: hidden;
    flex-direction: column;
    align-self: stretch;
    transition: max-height 0.25s ease-in-out;
    @media (min-width: 720px) {
        max-height: 100px;
        display: flex;
        flex-direction: row;
    }
`;

const DesktopLink = styled(Link)`
    text-decoration: none;
    color: #313444;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-left: 8px;
`;

const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: rgba(255, 255, 255, 0.12);
    }

    @media (max-width: 720px) {
        padding: 0.5rem 0rem;
        justify-content: flex-start;
    }
`;

const header = [
    {
        text: "Jaktteori",
        link: "/artiklar/om-det-teoretiska-provet/",
        icon: <BookIcon />,
    },
    {
        text: "Om Nya Jägarexamen",
        link: "/om-nya-jagarexamen/",
        icon: <CrownIcon />,
    },
    {
        text: "Så tar du jägarexamen",
        link: "/ta-jagarexamen/",
        icon: <QuestionIcon />,
    },
];

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <HeaderWrapper>
            <MenuButtonWrapper>
                <LogoWrapper>
                    <Link to={"/"}>
                        <StaticImage
                            width={162}
                            height={56}
                            src="../images/keps.svg"
                            alt="jägarkeps"
                            placeholder="blurred"
                            objectFit="contain"
                            objectPosition="center"
                        />
                    </Link>
                </LogoWrapper>
                <BurgerButton 
                    onClick={toggleMenu} isOpen={isOpen}>
                        Meny {isOpen ? <>&#x2715;</> : <>&#x2630;</>}
                </BurgerButton>
            </MenuButtonWrapper>
            <MenuLinksWrapper isOpen={isOpen}>
                {header.map((item) => (
                    <ItemWrapper key={item.text}>
                        {item.icon}
                        <DesktopLink to={item.link} onClick={toggleMenu}>{item.text}</DesktopLink>
                    </ItemWrapper>
                ))}
            </MenuLinksWrapper>
        </HeaderWrapper>
    );
};

export default Header;
