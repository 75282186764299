import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AngleDown from "../svg/angle-down.svg";
import AngleUp from "../svg/angle-up.svg";
import { Link } from "gatsby";
import useIsMobile from "hooks/useIsMobile";

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h4`
    margin: 16px 16px 0px 16px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    @media (max-width: 768px) {
        margin: 16px 0px;
    }
`;

const Menu = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const MenuItemLi = styled.li`
    padding: 12px 0px 12px 16px;
    display: flex;
    flex-direction: column;
`;

const MenuArticleGroup = styled.div`
    padding: 12px 0px;
    display: flex;
    flex-direction: column;
`;

const MenuItemTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin: 0px 16px;
    &:hover {
        cursor: pointer;
    }
`;

const MenuItemTitle = styled.h3`
    margin: 0;
    font-weight: 500;
    font-size: 20px;
`;

const MenuLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const MenuLinkBorder = styled(Link)`
    border-bottom: 1px solid #dccbb2;
    display: flex;
    text-decoration: none;
    background-color: ${(props) => (props.selected ? "#FBF4E9" : "none")};
    color: #313444;
    &:hover {
        background-color: #f2e8db;
    }
`;
const MenyListLinkText = styled.p`
    font-weight: 500;
    font-size: 18px;
    padding: 16px;
    margin: 0px 16px;
`;

const ArticleGroupTitle = styled.div`
    font-weight: 500;
    font-size: 18px;
`;

const ArticleList = ({ articleList, articleId }) => (
    <MenuLinkWrapper>
        {articleList.map((article) => {
            const selected = article.id === articleId;
            return (
                <MenuLinkBorder
                    to={`/artiklar/${article.path}`}
                    selected={selected}
                    key={article.title}
                >
                    <MenyListLinkText>{article.title}</MenyListLinkText>
                </MenuLinkBorder>
            );
        })}
    </MenuLinkWrapper>
);

const ArticleSubGroup = ({ title, articleList, onClick, open, articleId }) => {
    return (
        <MenuItemLi>
            <MenuItemTitleWrapper onClick={onClick}>
                <ArticleGroupTitle>{title}</ArticleGroupTitle>
                {open ? <AngleUp /> : <AngleDown />}
            </MenuItemTitleWrapper>
            {open && (
                <ArticleList articleList={articleList} articleId={articleId} />
            )}
        </MenuItemLi>
    );
};

const ArticleGroup = ({
    title,
    articleGroup,
    onClick,
    open,
    articleId,
    articleList,
}) => {
    const articleSelected = articleGroup.findIndex((list2) =>
        list2?.article.find((article) => article.id === articleId)
    );

    const [openSubGroup, setOpenSubGroup] = useState(articleSelected);
    const onSubGroupClick = (index) => {
        index === openSubGroup ? setOpenSubGroup(null) : setOpenSubGroup(index);
    };
    return (
        <MenuArticleGroup>
            <MenuItemTitleWrapper onClick={onClick}>
                <MenuItemTitle>{title}</MenuItemTitle>
                {open ? <AngleUp /> : <AngleDown />}
            </MenuItemTitleWrapper>
            {open && (
                <>
                    {articleList.length > 0 && (
                        <ArticleList
                            articleList={articleList}
                            articleId={articleId}
                        />
                    )}
                    {articleGroup.map((subGroup, index) => {
                        return (
                            <ArticleSubGroup
                                open={openSubGroup === index}
                                title={subGroup.title}
                                articleList={subGroup.article}
                                onClick={() => onSubGroupClick(index)}
                                articleId={articleId}
                                key={subGroup.title}
                            />
                        );
                    })}
                </>
            )}
        </MenuArticleGroup>
    );
};

const ArticleNav = ({ articleList, articleId }) => {
    const isMobile = useIsMobile();
    const [articlesOpen, setArticlesOpen] = useState(false);
    const articleSelected = articleList.findIndex((articleGroup) => {
        const articleInArticles = articleGroup.article.find(
            (article) => article.id === articleId
        );
        const articlesInGroup = articleGroup.article_group_1.some((list2) =>
            list2?.article.find((article) => article.id === articleId)
        );
        return articleInArticles || articlesInGroup;
    });

    useEffect(() => {
        if (isMobile) {
            setArticlesOpen(false);
        }
    }, [articleId]);

    const [openItemIndex, setOpenItemIndex] = useState(
        isMobile ? null : articleSelected
    );

    const onClick = (index) => {
        index === openItemIndex
            ? setOpenItemIndex(null)
            : setOpenItemIndex(index);
    };

    return (
        <ListWrapper>
            {isMobile ? (
                <MenuItemTitleWrapper
                    onClick={() => setArticlesOpen(!articlesOpen)}
                >
                    <Title>Artiklar</Title>
                    {articlesOpen ? <AngleUp /> : <AngleDown />}
                </MenuItemTitleWrapper>
            ) : (
                <Title>Artiklar</Title>
            )}

            {(!isMobile || articlesOpen) && (
                <Menu>
                    {articleList.map((articleGroup, index) => (
                        <ArticleGroup
                            key={articleGroup.title}
                            title={articleGroup.title}
                            articleGroup={articleGroup.article_group_1}
                            articleList={articleGroup.article}
                            onClick={() => onClick(index)}
                            open={openItemIndex === index}
                            articleId={articleId}
                        />
                    ))}
                </Menu>
            )}
        </ListWrapper>
    );
};

export default ArticleNav;
