import React from "react";
import styled from "styled-components";
import ArticleNav from "./articleNav";

const TemplateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #ABD3B9;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 12px;
    }
`;

const ArticleView = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    max-width: 960px;
    margin: 24px 12px 24px 24px;
    background-color: #efe3d2;
    border-radius: 16px;
    @media (max-width: 768px) {
        margin: 0;
        width: 100%;
    }
`;

const NavView = styled.div`
    width: 35%;
    max-width: 400px;
    background-color: #efe3d2;
    border-radius: 16px;
    color: #313444;
    margin: 24px 12px 24px 24px;
    @media (max-width: 768px) {
        margin: 24px 0;
        width: 100%;
    }
`;

const ArticleLayout = ({ pageContext, children }) => {
    return (
        <TemplateWrapper>
            <NavView>
                <ArticleNav
                    articleList={pageContext.pageContent.articleList}
                    articleId={pageContext.pageContent.id}
                />
            </NavView>
            <ArticleView>{children}</ArticleView>
        </TemplateWrapper>
    );
};

export default ArticleLayout;
