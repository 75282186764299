import React from "react";
import styled from "styled-components";
import Header from "./header";
import Footer from "./footer";

const AppWrapper = styled.div`
    min-width: 100%;
`;

const Layout = ({ children }) => (
    <AppWrapper>
        <Header />
        {children}
        <Footer />
    </AppWrapper>
);

export default Layout;
