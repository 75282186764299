import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const FooterWrapper = styled.div`
    width: 100%;
    display: flex;
    background-color: rgba(49, 52, 68, 1);
    flex-direction: column;
    align-items: center;
    padding: 36px 0px;
`;

const ItemWrapper = styled.div`
    display: flex;
    // display: grid;
    // grid-template-rows: 1fr 1fr 1fr;
    // flex-direction: row;
    flex-wrap: wrap;
    align-items: center; 
    justify-content: center;
    max-width: 480px; 
    padding-top: 16px;
    gap: 0.5rem;
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`;

const ItemLink = styled(Link)`
    padding: 16px 8px;
    text-decoration: underline;
    color: rgba(236, 238, 248, 1);
`;

const CompanyName = styled.p`
    color: rgba(236, 238, 248, 1);
`;

const footer = [
    {
        text: "Jaktteori",
        link: "/artiklar/om-det-teoretiska-provet/",
    },
    {
        text: "Privacy",
        link: "/privacy",
    },
    {
        text: "Ladda ned Nya Jägarexamen",
        link: "https://app.nyajagarexamen.se/Aag4/18rio8nk",
    },
    {
        text: "Facebook",
        link: "https://www.facebook.com/profile.php?id=100093655352307",
    },
    {
        text: "Instagram",
        link: "https://www.instagram.com/nyajagarexamen",
    },
];

const Header = () => (
    <FooterWrapper>
        <StaticImage
            width={146}
            height={128}
            src="../images/knife.svg"
            alt="knife"
            placeholder="blurred"
            quality={100}
        />

        <ItemWrapper>
            {footer.map((item) => (
                <ItemLink key={item.text} to={item.link}>
                    {item.text}
                </ItemLink>
            ))}
        </ItemWrapper>
        <CompanyName>© Liatorpet AB</CompanyName>
    </FooterWrapper>
);

export default Header;
